import { isNilOrEmpty, toBalance } from '@aifory/lil-tools-228'
import { Condition } from '@aifory/pochti-rabotaet'
import { Button, Divider, Flex, Skeleton, Text, useMantineTheme } from '@mantine/core'
import { CardPaper, KYCLevelType, getAdditionSizes } from 'ap-core'
import { useUnit } from 'effector-react'
import { $KYCLevel, $KYCLink, KycLimit } from 'entities'
import {
  addIndex,
  equals,
  find,
  gt,
  gte,
  inc,
  lt,
  map,
  prop,
  propEq,
  propOr,
} from 'ramda'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface LevelTextsProps {
  property?: string
  description?: string
  isLoading: boolean
  idx: number
  length: number
}
const LevelTexts = ({
  property,
  description,
  isLoading,
  idx,
  length,
}: LevelTextsProps) => {
  const { colors } = useMantineTheme()

  return (
    <Flex direction='column' key={property}>
      <Text fz='sm' color={colors.fadedGray[7]}>
        {property}
      </Text>
      <Skeleton visible={isLoading || !description}>
        <Text fz='sm' weight={600}>
          {description}
        </Text>
      </Skeleton>
      <Condition when={lt(idx, length - 1)}>
        <Divider mt={getAdditionSizes('s8')} />
      </Condition>
    </Flex>
  )
}

interface LevelDetailsProps {
  data?: KycLimit
  isLoading: boolean
  ids?: string
}
const LevelDetails = ({ data, isLoading, ids }: LevelDetailsProps) => {
  const { t } = useTranslation()

  const getLimitMessage = (type: string, data?: KycLimit) => {
    if (isNilOrEmpty(data)) return 0
    const byType = find(propEq(type, 'type'), prop('operationTypes', data!))
    if (!prop('LimitExist', byType!)) {
      return t(`Settings.Modals.KYC.Fields.levelDescription.cashLevels.4`)
    }
    return toBalance(prop('limit', byType!) || '0')
  }

  const fields = [
    {
      property: t('Settings.Modals.KYC.Fields.levelDescription.examination'),
      description: t(
        `Settings.Modals.KYC.Fields.levelDescription.examinationLevels.${
          propOr(1, 'KYCLevel', data) as KYCLevelType
        }`
      ),
    },
    {
      property: t('Settings.Modals.KYC.Fields.levelDescription.crypto'),
      description: t(
        `Settings.Modals.KYC.Fields.levelDescription.cashLevels.${
          propOr(1, 'KYCLevel', data) as KYCLevelType
        }`,
        {
          number: `${getLimitMessage('cryptoInvoice', data)} / ${getLimitMessage(
            'cryptoWithdrawal',
            data
          )}`,
        }
      ),
    },

    {
      property: t('Settings.Modals.KYC.Fields.levelDescription.cash'),
      description: t(
        `Settings.Modals.KYC.Fields.levelDescription.cashLevels.${
          propOr(1, 'KYCLevel', data) as KYCLevelType
        }`,
        {
          number: `${getLimitMessage('cashInvoice', data)} / ${getLimitMessage(
            'cashWithdrawal',
            data
          )}`,
        }
      ),
    },
    {
      property: t('Settings.Modals.KYC.Fields.levelDescription.nonCash'),
      description: t(
        `Settings.Modals.KYC.Fields.levelDescription.cashLevels.${
          propOr(1, 'KYCLevel', data) as KYCLevelType
        }`,
        {
          number: `${getLimitMessage('fiatInvoice', data)} / ${getLimitMessage(
            'fiatWithdrawal',
            data
          )}`,
        }
      ),
    },
  ]
  return (
    <Flex
      direction='column'
      px='md'
      gap={getAdditionSizes('s8')}
      mb='xs'
      id={`${ids}-KYCLevelLevelDetails`}
    >
      {addIndex<{ property: string; description: string }>(map)(
        (v, idx) => (
          <LevelTexts
            key={prop('property', v)}
            description={prop('description', v)}
            property={prop('property', v)}
            isLoading={isLoading}
            idx={idx}
            length={fields.length}
          />
        ),
        fields
      )}
    </Flex>
  )
}

interface LevelDescriptionProps {
  data?: KycLimit
  isLoading: boolean
  ids?: string
}

export const LevelDescription = ({ data, isLoading, ids }: LevelDescriptionProps) => {
  const { t } = useTranslation()
  const { colors, white } = useMantineTheme()
  const { 0: activeLevel, 1: link } = useUnit([$KYCLevel, $KYCLink])

  if (!data) return null

  const isActive = equals(prop('KYCLevel', data), activeLevel)

  const getTextColor = (isActive: boolean) => {
    if (isActive) return 'gray.0'
    return 'dark.5'
  }

  return (
    <CardPaper
      skipHoverEffect
      direction='column'
      gap={{ base: 'sm', md: 'md' }}
      p={{ base: 'xs', md: 'md' }}
    >
      <Flex
        p='md'
        align='center'
        justify='space-between'
        style={{
          background: isActive ? colors.brand[5] : colors.fadedGray[4],
          borderRadius: '12px',
        }}
      >
        <Flex gap='xs' align='center' justify='center'>
          <Flex
            style={{ backgroundColor: white, borderRadius: '12px' }}
            w={getAdditionSizes('s40')}
            h={getAdditionSizes('s40')}
            align='center'
            justify='center'
          >
            <Text
              id={`${ids}-KYCLevelDescLevelText`}
              fz={{ base: 'md', md: 'xl' }}
              color={isActive ? 'brand.5' : 'dark.5'}
              weight={600}
            >
              {prop('KYCLevel', data)}
            </Text>
          </Flex>

          <Flex direction='column'>
            <Text color={getTextColor(isActive)} id={`${ids}-KYCLevelDescLevel1Text`}>
              {t('Settings.Modals.KYC.Fields.level')}
            </Text>
            <Skeleton visible={isLoading}>
              <Text
                color={getTextColor(isActive)}
                weight={600}
                id={`${ids}-KYCLevelDescLevel2Text`}
              >
                {t(`Settings.Modals.KYC.Fields.levels.${prop('KYCLevel', data!)}`)}
              </Text>
            </Skeleton>
          </Flex>
        </Flex>
        <Condition when={isActive}>
          <Text
            color={getTextColor(isActive)}
            weight={600}
            id={`${ids}-KYCLevelDescLevel3Text`}
          >
            {t('Settings.Modals.KYC.Fields.levelDescription.current')}
          </Text>
        </Condition>
      </Flex>

      <LevelDetails data={data} isLoading={isLoading} ids={`${ids}-KYCLevelDet`} />

      <Skeleton visible={isLoading}>
        <Condition when={!isActive && gte(prop('KYCLevel', data!), activeLevel || 0)}>
          <Button
            id={`${ids}-KYCLevelDescLevelUpBtn`}
            component={Link}
            to={link}
            target='_blank'
            disabled={isLoading || gt(prop('KYCLevel', data!), inc(activeLevel || 0))}
            fullWidth
          >
            {t('Settings.Modals.KYC.Fields.levelDescription.levelUp', {
              number: prop('KYCLevel', data),
            })}
          </Button>
        </Condition>
      </Skeleton>
    </CardPaper>
  )
}
