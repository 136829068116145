import { OperationLimitReq, Rate } from 'ap-core'
import { createEvent } from 'effector'

export const userRateChanged = createEvent<Rate>()

export const userResetChanged = createEvent()

export const resetPriorityEmail = createEvent()

export const getKYCVerifyLinks = createEvent<number>()

export const resetKYCLink = createEvent()

export const resetKYCOperationLimit = createEvent()

export const triggerGetKYCOperationLimit = createEvent<OperationLimitReq>()
export const triggerGetUserDetails = createEvent()
export const triggerGetKYCLevel = createEvent()
