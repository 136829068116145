import { Button, Flex, Text } from '@mantine/core'
import { getAdditionSizes } from 'ap-core'
import { always, ifElse, split } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { AuthURLs, URLs, isAuthRoutes } from 'shared'

const getRedirectPath = ifElse(
  isAuthRoutes,
  always(AuthURLs.login.path),
  always(URLs.wallet.path)
)
const PageNotExist = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  return (
    <Flex
      h='100dvh'
      align='center'
      justify='center'
      gap={getAdditionSizes('s32')}
      direction='column'
    >
      <Flex direction='column' align='center' justify='center'>
        <Text
          id='PageNotExist404Text'
          weight={600}
          fz={{ base: 160, md: 240 }}
          color='brand.5'
        >
          404
        </Text>
        <Text
          id='PageNotExistMessageText'
          weight={600}
          fz={{ base: 'xl', md: getAdditionSizes('s24') }}
        >
          {t('Page404.message')}
        </Text>
      </Flex>

      <Button
        id='PageNotExistBackBtn'
        component={Link}
        to={getRedirectPath(split(/\/|#/, pathname))}
        color='brand.5'
      >
        {t('Page404.button')}
      </Button>
    </Flex>
  )
}

export default PageNotExist
