import { alwaysNew, isNilOrEmpty, updateToNewDataProp } from '@aifory/lil-tools-228'
import {
  KYCLevelType,
  OperationLimitRes,
  Rate,
  UserSettings,
  VerificationType,
} from 'ap-core'
import { createStore, sample } from 'effector'
import { __, always, complement, gte, ifElse, inc, length, objOf } from 'ramda'
import { sseUserDetailsChanged } from '../events'
import * as ef from './effects'
import * as ev from './events'
import { KycLimit } from './types'

export const $username = createStore<string>('')
export const $techSupportLink = createStore<string>('')
export const $tgUsername = createStore<string>('')
export const $verificationType = createStore<VerificationType | null>(null)
export const $userDetails = createStore<UserSettings | null>(null)
export const $rate = createStore<Rate | null>(null)

export const $email = createStore<string>('')
export const $referralToken = createStore<string>('')
export const $invited = createStore<number>(0)

export const $userID = createStore<number>(0)

export const $KYCLink = createStore<string>('')
export const $KYCOperationLimit = createStore<OperationLimitRes | null>(null)
export const $KYCLimits = createStore<KycLimit[]>([])
export const $KYCLevel = createStore<KYCLevelType | null>(null)

export const $role = createStore('client')

$KYCLevel.on(
  [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
  updateToNewDataProp('kycLevel')
)

$KYCLimits.on(ef.getKYCLevelFx.doneData, alwaysNew)

$KYCLink.on(ef.getKYCLinkFx.doneData, updateToNewDataProp('link')).reset(ev.resetKYCLink)

$KYCOperationLimit
  .on(ef.getKYCOperationLimitFx.doneData, alwaysNew)
  .reset(ev.resetKYCOperationLimit)

$invited.on(
  [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
  updateToNewDataProp('numOfInvitedUsers')
)

$referralToken.on(
  [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
  updateToNewDataProp('referralToken')
)

$email
  .on([ef.getUserDetailsFx.doneData, sseUserDetailsChanged], updateToNewDataProp('email'))
  .reset(ev.resetPriorityEmail)

$username
  .on(
    [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
    updateToNewDataProp('username')
  )
  .reset(ev.userResetChanged)

$techSupportLink.on(
  [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
  updateToNewDataProp('techSupportLink')
)

$userDetails
  .on(
    [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
    updateToNewDataProp('settings')
  )
  .reset(ev.userResetChanged)

$tgUsername
  .on(
    [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
    updateToNewDataProp('tgUsername')
  )
  .reset(ev.userResetChanged)

$verificationType
  .on(
    [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
    updateToNewDataProp('verificationType')
  )
  .reset(ev.userResetChanged)

$rate
  .on(ev.userRateChanged, (state, payload: any) => ({ ...(state || {}), ...payload }))
  .reset(ev.userResetChanged)

$userID
  .on(
    [ef.getUserDetailsFx.doneData, sseUserDetailsChanged],
    updateToNewDataProp('userID')
  )
  .reset(ev.userResetChanged)

sample({
  clock: ev.getKYCVerifyLinks,
  source: $KYCLimits,
  filter: complement(isNilOrEmpty),
  fn: (limits, level) =>
    ifElse(
      gte(__, level),
      always({ KYCLevel: level }),
      objOf('KYCLevel')
    )(length(limits)),
  target: ef.getKYCLinkFx,
})

sample({
  clock: [ef.getKYCLevelFx.doneData],
  source: $KYCLevel,
  fn: (level) => {
    if (!isNilOrEmpty(level)) return inc(level!)
    return 2
  },
  target: ev.getKYCVerifyLinks,
})

sample({
  clock: ev.triggerGetKYCOperationLimit,
  target: ef.getKYCOperationLimitFx,
})

sample({
  clock: ev.triggerGetUserDetails,
  target: ef.getUserDetailsFx,
})

sample({
  clock: ev.triggerGetKYCLevel,
  target: ef.getKYCLevelFx,
})

sample({
  clock: ef.getUserDetailsFx.doneData,
  target: ev.triggerGetKYCLevel,
})
