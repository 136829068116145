import { sample } from 'effector'
import { getAPKLinkFx } from './effects'
import { triggerGetAPKLink } from './events'

sample({
  clock: triggerGetAPKLink,
  target: getAPKLinkFx,
})

export * from './effects'
export * from './events'
export * from './store'
