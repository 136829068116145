import { hideNotification } from '@mantine/notifications'
import { NotifyProps } from 'ap-core'
import { sample } from 'effector'
import { notifyFx } from '../effects'
import { $translate } from '../store'
import { getWsTokenFx } from './effects'
import { hideReconnectNotify, showReconnectNotify, wsTokenChanged } from './events'

const wsId = 'wsReconnect'

sample({
  clock: wsTokenChanged,
  target: getWsTokenFx,
})

sample({
  clock: showReconnectNotify,
  source: $translate,
  fn: (t): NotifyProps => ({
    t,
    passToTranslateMessage: 'Shared.Notifications.wsReconnect',
    type: 'danger',
    id: wsId,
    loading: true,
    autoClose: false,
  }),
  target: notifyFx,
})

sample({
  clock: hideReconnectNotify,
  fn: () => hideNotification(wsId),
})

export * from './effects'
export * from './events'
export * from './store'
