import { alwaysNew, isNilOrEmpty } from '@aifory/lil-tools-228'
import {
  Account,
  AssetInterface,
  LocationByCurrency,
  MobileWalletTabs,
  P2PInvoice,
  TWallet,
} from 'ap-core'
import { createStore, sample } from 'effector'
import {
  always,
  complement,
  concat,
  equals,
  find,
  head,
  ifElse,
  includes,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
} from 'ramda'
import { Method } from 'srv-declarations'
import { resetOperationsStore, switchLangChanged } from '../events'
import { DEMO_WALLET, updateOrDelete } from './consts'
import { checkBalanceFx, getAccountsLocationsFx, getAssetsFx } from './effects'
import * as ev from './events'
import { Assets } from './types'
import { getWalletByLocale, getWalletsFromGroup, updateUngroupedWallets } from './utils'

export const $activeWallet = createStore<TWallet | null>(null)
export const $activeAccount = createStore<Account | null>(null)
export const $accountsLocations = createStore<LocationByCurrency[]>([])
// export const $groupedAssets = createStore<AssetsGroup[]>([])
export const $ungroupedAssets = createStore<TWallet[]>([])
export const $walletsList = createStore<TWallet[]>([])
export const $activeMobileTab = createStore<MobileWalletTabs>('left')
export const $P2PPaymentStatus = createStore<P2PInvoice | null>(null)

export const $assetsInterfaces = createStore<AssetInterface[]>([
  { name: 'list', visible: true, order: 0 },
  { name: 'operations', visible: true, order: 1 },
  { name: 'history', visible: true, order: 2 },
])

$assetsInterfaces.on(ev.assetsInterfacesChanged, alwaysNew)
$P2PPaymentStatus.on(ev.triggerChangeP2PPaymentStatus, alwaysNew)

export const $currentAssetHistoryId = createStore<string>('').on(
  ev.currentWalletIdChanged,
  alwaysNew
)
export const $selectedPaymentMethod = createStore<Method | null>('LEGAL_PAYMENT').reset(
  resetOperationsStore
)
export const $selectedPaymentsHistory = createStore<string>('')

sample({
  clock: [$activeWallet, $activeAccount],
  source: { activeWallet: $activeWallet, activeAccount: $activeAccount },
  fn: ifElse(
    pipe(prop('activeAccount'), complement(isNilOrEmpty)),
    pathOr('', ['activeAccount', 'id']),
    pathOr('', ['activeWallet', 'id'])
  ),
  target: ev.currentWalletIdChanged,
})

// $groupedAssets
//   .on(getAssetsFx.doneData, (_, payload: any) => payload.groups)
//   .on(ev.groupedAssetsChanged, (_, newGroups) => newGroups)
//   .on(ev.assetSSEChanged, (state, payload) =>
//     updateGroupedWallets(state, getWalletByLocale(payload) as any)
//   )
//   .reset(ev.resetAssetsChanged)

$activeMobileTab
  .on(ev.mobileActiveTabChanged, alwaysNew)
  .reset(ev.resetSharedAssetsChanged)

$ungroupedAssets
  .on(ev.ungroupedAssetsChanged, alwaysNew)
  .on(ev.assetSSEChanged, (state, payload) =>
    updateUngroupedWallets(
      state,
      getWalletByLocale(payload)!,
      path(['operation', 'type'], payload)
    )
  )
  .on(ev.addDemoWallet, (state) => {
    ev.activeWalletChanged(DEMO_WALLET)

    return updateUngroupedWallets(state, DEMO_WALLET, 'create')
  })
  .on(ev.removeDemoWallet, (state) => {
    const updated = updateUngroupedWallets(state, DEMO_WALLET, 'delete')
    ev.activeWalletChanged(head(updated)!)
    return updated
  })
  .reset(ev.resetSharedAssetsChanged)

$activeWallet
  .on(getAssetsFx.doneData, (_, payload: Assets) =>
    ifElse<[TWallet[]], TWallet | null, TWallet | null>(
      isNilOrEmpty,
      always(_),
      head
    )(prop('ungroupedWallets', payload))
  )
  .on(ev.activeWalletChanged, alwaysNew)
  .reset(ev.resetSharedAssetsChanged)

$activeAccount.on(ev.activeAccountChanged, alwaysNew).reset(ev.resetSharedAssetsChanged)

$walletsList
  .on(getAssetsFx.doneData, (_, payload) =>
    concat(
      getWalletsFromGroup(payload),
      prop('ungroupedWallets', payload as Assets) as TWallet[]
    )
  )
  .on(ev.assetSSEChanged, (walletsList, updated) => {
    return updateUngroupedWallets(
      walletsList,
      getWalletByLocale(updated)!,
      path(['operation', 'type'], updated)
    )
  })
  .reset(ev.resetSharedAssetsChanged)

$accountsLocations
  .on(getAccountsLocationsFx.doneData, alwaysNew)
  .reset(ev.resetSharedAssetsChanged)

sample({
  clock: switchLangChanged,
  target: [getAccountsLocationsFx, getAssetsFx],
})

sample({
  clock: ev.triggerGetAssets,
  target: getAssetsFx,
})

sample({
  clock: ev.triggerGetAccountsLocations,
  target: getAccountsLocationsFx,
})

sample({
  clock: ev.triggerCheckBalance,
  target: checkBalanceFx,
})

sample({
  clock: ev.assetSSEChanged,
  source: { ungroupedAssets: $ungroupedAssets, activeWallet: $activeWallet },
  fn: ({ ungroupedAssets, activeWallet }, evData) => {
    const type = path(['operation', 'type'], evData)
    const targetWallet = getWalletByLocale(evData)

    const currentActiveIsTarget = equals(
      prop('id', targetWallet!),
      prop('id', activeWallet!)
    )

    const headOfAssets = head(ungroupedAssets) as TWallet
    if (includes(type, updateOrDelete)) {
      if (equals('delete', type) && currentActiveIsTarget) return headOfAssets

      if (equals('create', type)) return targetWallet

      if (equals('update', type) && currentActiveIsTarget) return targetWallet
      return activeWallet
    }

    return headOfAssets
  },
  target: ev.activeWalletChanged,
})

$selectedPaymentMethod.on(ev.triggerPaymentMethodChanged, alwaysNew)

$selectedPaymentsHistory.on(getAssetsFx.doneData, (cur, assets) => {
  return pipe(
    find(propEq(2001, 'currencyID')),
    propOr('', 'id')
  )(assets.ungroupedWallets) as string
})

sample({
  clock: ev.triggerUpdateAssetsHistory,
  filter: ({ type }) => includes(type, ['cryptoInvoice', 'cryptoTransferWithdrawal']),
  fn: ({ type }) => {
    if (equals(type, 'cryptoInvoice')) {
      window.ym(propOr(123, 'YM_COUNTER_ID', window), 'reachGoal', 'crypto_deposit')
      return
    } else {
      window.ym(propOr(123, 'YM_COUNTER_ID', window), 'reachGoal', 'withdraw')
      window.ym(propOr(123, 'YM_COUNTER_ID', window), 'reachGoal', 'crypto_withdraw')
      return
    }
  },
})
