import { TWallet } from 'ap-core'
import { includes, path, prop } from 'ramda'

export const updateOrDelete = ['create', 'update', 'delete']

export const DEMO_WALLET = {
  balance: '10000',
  accounts: [
    {
      balance: '10000',
      frozenBalance: '10000',
      city: { id: 1, name: 'Москва' },
      country: { id: 1, name: 'Россия' },
      createdAt: Math.floor(Date.now() / 1000),
      currencyID: 1000,
      currencyName: 'RUB',
      id: 'fakeAccount',
    },
  ],
  frozenBalance: '10000',
  createdAt: Math.floor(Date.now() / 1000),
  currencyID: 1000,
  currencyName: 'RUB',
  id: 'fake',
  name: 'Example',
} as TWallet

export const isNotDemo = (id: string) =>
  !includes(id, [prop('id', DEMO_WALLET), path(['accounts', 0, 'id'], DEMO_WALLET)])
