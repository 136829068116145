import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { Condition } from '@aifory/pochti-rabotaet'
import { Flex, Text, useMantineTheme } from '@mantine/core'
import { AlertDescription } from 'ap-core'
import { useUnit } from 'effector-react'
import { $KYCLimits, KycLimit, getKYCLevelFx } from 'entities'
import { map, prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { IconAttention, safeMap } from 'shared'
import { LevelDescription } from './utils'

interface Props {
  is430: boolean
  ids?: string
}
const Content = ({
  KYCLimits,
  isLoading,
  ids,
}: {
  KYCLimits: KycLimit[]
  isLoading: boolean
  ids?: string
}) => {
  if (isNilOrEmpty(KYCLimits) || isLoading)
    return (
      <>
        {map(
          (v) => (
            <LevelDescription isLoading={isLoading} data={undefined} key={v} ids={ids} />
          ),
          Array.from(Array(3).keys())
        )}
      </>
    )
  return (
    <>
      {safeMap(
        (el) => (
          <LevelDescription
            isLoading={isLoading}
            data={el}
            key={prop('KYCLevel', el)}
            ids={ids}
          />
        ),
        KYCLimits
        // || [undefined, undefined, undefined] не срабатывает
      )}
    </>
  )
}

export const KYCInfo = ({ is430 }: Props) => {
  const { t } = useTranslation()
  const { colors } = useMantineTheme()
  const { 0: KYCLimits, 1: isLoading } = useUnit([$KYCLimits, getKYCLevelFx.pending])

  return (
    <Flex direction='column' gap='lg'>
      <Condition when={is430}>
        <AlertDescription
          ids='KYCInfoAlert'
          bgColor={colors.yellow[0]}
          description={t('Settings.Modals.KYC.Fields.errorDescription')}
          title={
            <Text fz='md' weight={600} id='KYCInfoAlertText'>
              {t('Settings.Modals.KYC.Fields.error')}
            </Text>
          }
          titleIcon={<IconAttention color={colors.yellow[5]} id='KYCInfoAttentionIcon' />}
        />
      </Condition>
      <Content KYCLimits={KYCLimits} isLoading={isLoading} ids='KYCInfoContent' />
    </Flex>
  )
}
