import { Flex } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { compareWithAndBreakPoint } from 'ap-core'
import { gt } from 'ramda'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  banner: ReactNode
}

const LoginFormWithLogo = ({ children, banner }: Props) => {
  const { width } = useViewportSize()

  if (compareWithAndBreakPoint(gt, width, 'md')) return <>{children}</>
  else
    return (
      <Flex mt={15} w='100%' mah={480} maw={500} justify='center' direction='column'>
        <Flex
          m='auto'
          justify='center'
          w={150}
          h={150}
          style={{
            position: 'relative',
          }}
        >
          {banner}
        </Flex>
        {children}
      </Flex>
    )
}

export default LoginFormWithLogo
