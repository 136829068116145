import { isNilOrEmpty, toBalance } from '@aifory/lil-tools-228'
import { Skeleton, Text } from '@mantine/core'
import { OperationLimit, TFn } from 'ap-core'
import { Event } from 'effector'
import { prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'

export const handleClick = (
  isLoadingLimit: boolean,
  isLoadingLevel: boolean,
  triggerKYCModal: Event<{ isError: boolean }>
) => {
  if (isLoadingLimit && isLoadingLevel) return
  return triggerKYCModal({ isError: false })
}

const getText = (t: TFn, limit?: OperationLimit | null) => {
  if (isNilOrEmpty(limit)) return

  return t('Settings.Modals.KYC.Fields.levelDescription.byDay', {
    number: toBalance(propOr('0', 'limit', limit)),
  })
}

export const GetLimitIfExist = ({
  isLoadingLimit,
  limit,
}: {
  isLoadingLimit: boolean
  limit: OperationLimit | null
}) => {
  const { t } = useTranslation()

  if (prop('limitExist', limit!))
    return (
      <Skeleton visible={isLoadingLimit}>
        <Text id='GetLimitIfExistLimitText' fz={{ base: 'sm', md: 'md' }} weight={600}>
          {getText(t, limit)}
        </Text>
      </Skeleton>
    )

  return (
    <Text id='GetLimitIfExistLevelText' fz={{ base: 'sm', md: 'md' }} weight={600}>
      {t('Settings.Modals.KYC.Fields.levelDescription.cashLevels.4')}
    </Text>
  )
}
