import { NotifyProps } from 'ap-core'
import { createStore, sample } from 'effector'
import { T } from 'ramda'
import { IconNetworkOff } from 'shared'
import { notifyFx } from '../effects'
import { $translate } from '../store'
import * as ev from './events'

export const $displayedWeakConnectNotify = createStore(false)

$displayedWeakConnectNotify.on(ev.displayWeakConnectNotifyChanged, T)

sample({
  clock: ev.displayWeakConnectNotifyChanged,
  source: $translate,
  fn: (t): NotifyProps => ({
    t,
    type: 'danger',
    passToTranslateMessage: 'Shared.Notifications.WeakConnect.message',
    passToTranslateTitle: 'Shared.Notifications.WeakConnect.title',
    autoClose: false,
    icon: <IconNetworkOff />,
    color: 'red',
  }),
  target: notifyFx,
})
