import { Flex, Paper, Text } from '@mantine/core'
import { Fn, getAdditionSizes } from 'ap-core'
import { useTranslation } from 'react-i18next'
import { IconApp, IconShare, IconX } from 'shared'

interface Props {
  show: boolean
  onClose: Fn<any, void>
}

const PwaDescription = ({ show, onClose }: Props) => {
  const { t } = useTranslation()

  if (!show) return null

  return (
    <Paper shadow='xl' w='92%' p='xs' style={{ position: 'absolute', zIndex: 10 }}>
      <Flex justify='space-between' gap={getAdditionSizes('s4')}>
        <IconApp width={85} id='PwaDescriptionIconApp' />
        <Flex direction='column' gap={getAdditionSizes('s8')}>
          <Text align='center' weight={600} fz='md' id='PwaDescriptionTitle'>
            {t('Shared.PwaGuid.title')}
          </Text>
          <Flex wrap='wrap' justify='center' gap={getAdditionSizes('s4')}>
            <Text id='PwaDescriptionText1' fz='xs'>
              {' '}
              {t('Shared.PwaGuid.Content.part1')}{' '}
            </Text>
            <IconShare width={35} id='PwaDescriptionShareIcon' />
            <Text id='PwaDescriptionText2' fz='xs'>
              {' '}
              {t('Shared.PwaGuid.Content.part2')}{' '}
            </Text>
          </Flex>
        </Flex>
        <IconX onClick={onClose} id='PwaDescriptionX' />
      </Flex>
    </Paper>
  )
}
export default PwaDescription
