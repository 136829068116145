import { OperationLimitReq, OperationLimitRes, UserDetails } from 'ap-core'
import { createEffect } from 'effector'
import { prop } from 'ramda'
import { createApiRetryEffect } from '../store'
import { getUserEndpoints, logout } from './api'
import { GetKYCLinksTypeReq, KycLimit } from './types'

const endpoints = getUserEndpoints()

export const getUserDetailsFx = createApiRetryEffect<void, UserDetails>({
  endpoint: prop('DETAILS', endpoints),
})

export const getKYCLevelFx = createApiRetryEffect<void, KycLimit[]>({
  endpoint: prop('GET_KYC_LEVEL', endpoints),
})

export const getKYCLinkFx = createApiRetryEffect<GetKYCLinksTypeReq, { link: string }>({
  endpoint: prop('GET_KYC', endpoints),
})

export const getKYCOperationLimitFx = createApiRetryEffect<
  OperationLimitReq,
  OperationLimitRes
>({
  endpoint: prop('GET_KYC_OPERATION_LIMIT', endpoints),
})

export const logoutFx = createEffect(logout)
