import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { NotificationProps, showNotification } from '@mantine/notifications'
import { NotifyProps, fileToBase64 } from 'ap-core'
import { createEffect } from 'effector'
import { T, always, cond, equals, prop, propOr } from 'ramda'
import { RetryFailedReqsFxData } from './types'
import { openConfirmWithdrawModal, throwSentryError } from './utils'

const getColorByType = cond([
  [equals('danger'), always('orange')],
  [equals('fail'), always('red')],
  [equals('success'), always('green')],
  [T, always('orange')],
])

const getDefaultTitleAndMessage = (type: NotifyProps['type']) => {
  if (equals(type, 'success')) {
    return {
      title: 'Shared.Notifications.Success.defaultTitle',
      message: 'Shared.Notifications.Success.defaultMessage',
    }
  }
  if (equals(type, 'fail')) {
    return {
      title: 'Shared.Notifications.Error.defaultTitle',
      message: 'Shared.Notifications.Error.defaultMessage',
    }
  }

  return {
    title: 'Shared.Notifications.Warning.defaultTitle',
    message: 'Shared.Notifications.Warning.defaultMessage',
  }
}

const getTitleAndMessage = (config: NotifyProps) => {
  const t = prop('t', config)
  if (isNilOrEmpty(t)) return { message: '', title: '' }
  const type = prop('type', config)
  const { message, title } = getDefaultTitleAndMessage(type)
  return {
    message: t(propOr(message, 'passToTranslateMessage', config)),
    title: t(propOr(title, 'passToTranslateTitle', config)),
  }
}

export const notifyFx = createEffect((params?: NotifyProps) => {
  if (isNilOrEmpty(params)) return undefined

  showNotification({
    autoClose: 5000,
    color: getColorByType(prop('type', params!)),
    ...getTitleAndMessage(params!),
    withBorder: true,
    ...params,
  } as NotificationProps)
})

export const toConfirmWithdrawFx = createEffect(openConfirmWithdrawModal)

export const throwSentryErrorFx = createEffect((error: string | unknown) => {
  throwSentryError(error)
})

export const retryFailedOn401Fx = createEffect<RetryFailedReqsFxData, any>()

export const fileToBase64Fx = createEffect(fileToBase64)
