import { constructEndpoint } from 'ap-core'
import { prop } from 'ramda'
import { processingResponseKy, request } from 'shared'

export const getUserEndpoints = () => ({
  DETAILS: constructEndpoint('/details/user'),
  PASS_TG_CODE: constructEndpoint('/client/send_tg_code'),
  GET_KYC: constructEndpoint('/auth/client/get_kyc_verify_link'),
  GET_KYC_LEVEL: constructEndpoint('/auth/client/get_limits'),
  GET_KYC_OPERATION_LIMIT: constructEndpoint('/auth/client/get_limit'),
  LOGOUT: constructEndpoint('/auth/client/sign-out'),
})

export const logout = () =>
  processingResponseKy<void>(() => request.post(prop('LOGOUT', getUserEndpoints())))
