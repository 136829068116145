import { Flex, useMantineTheme } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { compareWithAndBreakPoint } from 'ap-core'
import { gt, path } from 'ramda'
import { AuthImg, IconAuthMobile, isDark } from 'shared'

const AuthBanner = () => {
  const { colors, colorScheme } = useMantineTheme()
  const { width } = useViewportSize()
  const color = isDark(colorScheme)
    ? path(['gray', 0], colors)
    : path(['dark', 8], colors)
  return (
    <Flex
      h='100%'
      justify='center'
      direction='column'
      p={{ base: 0, md: 'lg' }}
      align='center'
    >
      {compareWithAndBreakPoint(gt, width, 'md') ? (
        <AuthImg color={color} />
      ) : (
        <IconAuthMobile color={color} />
      )}
    </Flex>
  )
}

export default AuthBanner
