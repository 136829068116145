import { Currency } from 'ap-core'
import { always, assocPath, ifElse, lte, prop, reduce } from 'ramda'

const updateList = (item: Currency, listName: 'crypto' | 'fiat') =>
  assocPath([listName, prop('name', item)], prop('id', item))
export const getCurrencies = (arr: Currency[]) => {
  return reduce(
    (acc, item) =>
      ifElse(
        lte(2000),
        always(updateList(item, 'crypto')(acc)),
        always(updateList(item, 'fiat')(acc))
      )(prop('id', item)) as { fiat: {}; crypto: {} },
    { fiat: {}, crypto: {} },
    arr
  )
}
