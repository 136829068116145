import { Transaction } from 'ap-core'
import { legalOrderDetailsChanged, orderDetailsChanged } from 'entities'
import { includes, prop, propOr } from 'ramda'

export const handleOpenTransactionModal = (item: Transaction) => {
  if (item) {
    const type = prop('orderType', item) || prop('type', item)
    const orderID =
      propOr<string, Transaction, string>('', 'orderID', item) ||
      propOr(prop('id', item), 'processID', item)
    const isLegalPayment = includes('cbTransfer', type || '')

    if (isLegalPayment) {
      return legalOrderDetailsChanged({ orderID })
    }

    orderDetailsChanged(item)
  }
}
