import { SwitchTransitionAnimation } from '@aifory/pochti-rabotaet'
import { Paper, Tabs, Text, useMantineTheme } from '@mantine/core'
import { MobileWalletTabs, TFn } from 'ap-core'
import { always, equals, ifElse, prop } from 'ramda'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { safeMap } from 'shared'
import { MobileTabsProps } from '../types'

interface Props {
  left: ReactNode
  middle: ReactNode
  right: ReactNode
  active: string
  onTabChange: (tab: string) => void
}

const getTabs = (t: TFn): { value: MobileWalletTabs; label: string }[] => {
  return [
    { value: 'left', label: t('Wallet.Actives.Title.base') },
    { value: 'middle', label: t('Shared.Routes.wallet') },
    { value: 'right', label: t('Shared.Routes.history') },
  ]
}

const MobileTabs = ({ left, middle, onChangeTab, right, active }: MobileTabsProps) => {
  const { t } = useTranslation()
  const { colors } = useMantineTheme()

  const tabsData = getTabs(t)
  return (
    <Tabs h='100%' value={active}>
      <Paper
        style={{
          flex: 1,
          flexBasis: '30%',
          position: 'sticky',
          top: '0.1rem',
          zIndex: 2,
          overflow: 'hidden',
        }}
        mb='xs'
      >
        <Tabs.List defaultValue='middle' grow position='center'>
          {safeMap((v) => {
            return (
              <Tabs.Tab
                key={v.value}
                value={v.value}
                onClick={() => onChangeTab(v.value)}
              >
                <Text
                  weight={600}
                  fz={{ base: 'xs', md: 'md' }}
                  style={{
                    color: ifElse(
                      equals(active),
                      always(colors.brand[5]),
                      always(colors.fadedGray[7])
                    )(prop('value', v)),
                  }}
                >
                  {v.label}
                </Text>
              </Tabs.Tab>
            )
          }, tabsData)}
        </Tabs.List>
      </Paper>

      <Tabs.Panel value='left' h='calc(100% - 60px)'>
        <SwitchTransitionAnimation
          containerStyles={{ width: '100%', height: '100%' }}
          key={active}
          transitionType='fade'
          duration={0.7}
        >
          <Paper h='100%'>{left}</Paper>
        </SwitchTransitionAnimation>
      </Tabs.Panel>
      <Tabs.Panel value='middle' h='calc(100% - 60px)'>
        <SwitchTransitionAnimation
          containerStyles={{ width: '100%', height: '100%' }}
          key={active}
          transitionType='fade'
          duration={0.7}
        >
          <Paper h='100%'>{middle}</Paper>
        </SwitchTransitionAnimation>
      </Tabs.Panel>
      <Tabs.Panel value='right' h='calc(100% - 60px)'>
        <SwitchTransitionAnimation
          containerStyles={{ width: '100%', height: '100%' }}
          key={active}
          transitionType='fade'
          duration={0.7}
        >
          <Paper h='100%'>{right}</Paper>
        </SwitchTransitionAnimation>
      </Tabs.Panel>
    </Tabs>
  )
}

export default MobileTabs
