import { sample } from 'effector'
import { $translate } from 'entities'
import { openKYCModal } from 'features/KYC-info/utils'
import { prop } from 'ramda'
import { triggerKYCModal } from 'shared'

export * from './ui'
sample({
  clock: triggerKYCModal,
  source: $translate,
  fn: (t, params) => {
    openKYCModal(t, prop('isError', params))
  },
})
