import type { ColorScheme } from '@mantine/core'
import { ColorSchemeProvider, MantineProvider } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { Notifications } from '@mantine/notifications'
import { compareWithAndBreakPoint } from 'ap-core'
import { useUnit } from 'effector-react/effector-react.umd'
import { $theme, themeChanged } from 'entities'
import { gt } from 'ramda'
import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { isDark } from 'shared'
import { getCustomTheme } from './theme'

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const colorScheme = useUnit($theme)
  const { pathname } = useLocation()
  const { width } = useViewportSize()
  const theme = useMemo(
    () =>
      getCustomTheme(
        colorScheme,
        compareWithAndBreakPoint(gt, width, 'md') ? 'desktop' : 'mobile'
      ),
    [pathname, colorScheme, width]
  )

  const toggleColorScheme = (value?: ColorScheme) => {
    const nextColorScheme = value || (isDark(colorScheme) ? 'light' : 'dark')
    themeChanged(nextColorScheme)
  }
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ primaryShade: 5, ...theme }}
      >
        <Notifications
          position={
            compareWithAndBreakPoint(gt, width, 'sm') ? 'bottom-right' : 'bottom-center'
          }
        />
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  )
}

export default ThemeProvider
