import { Skeleton, useMantineTheme } from '@mantine/core'
import { useUnit } from 'effector-react'
import { getKYCLevelFx, getUserDetailsFx } from 'entities'
import { IconInfo } from 'shared'
export const TriggerKycInfo = () => {
  const { colors } = useMantineTheme()
  const { 0: isLoadingLevel, 1: isLoadingLimit } = useUnit([
    getUserDetailsFx.pending,
    getKYCLevelFx.pending,
  ])

  return (
    <Skeleton visible={isLoadingLimit || isLoadingLevel}>
      <IconInfo style={{ color: colors.orange[5] }} />
    </Skeleton>
  )
}
