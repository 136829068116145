import { ScreenSize } from '../../types'
import { getLabelSize } from '../../utils'

export const getBaseSelectInputStyles = (screenSize: ScreenSize) => ({
  root: {
    width: '100%',
  },
  input: {
    borderRadius: '12px',
  },
  label: {
    fontSize: getLabelSize(screenSize),
  },
})
