import { constructEndpoint } from 'ap-core'

export const getLegalEndpoints = () => ({
  GET_DETAILS: constructEndpoint('/details/order/info'),
  CANCEL_ORDER: constructEndpoint('/order/cancel'),
  GET_LEGAL_DETAILS: constructEndpoint('/cb-transfer/order/details'),
  CANCEL_LEGAL_WITHDRAW_ORDER: constructEndpoint('/cb-transfer/order/withdraw/cancel'),
  CANCEL_LEGAL_INVOICE_ORDER: constructEndpoint('/cb-transfer/order/invoice/cancel'),
  CONFIRM_LEGAL_WITHDRAW: constructEndpoint('/cb-transfer/order/withdraw/confirm'),
  CONFIRM_LEGAL_INVOICE: constructEndpoint('/cb-transfer/order/invoice/confirm'),
  GET_PAYMART_DETAILS: constructEndpoint('/services/details'),
})
