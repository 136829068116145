import { GetTransactionDetailsRes } from 'entities'
import { prop } from 'ramda'
import { createApiRetryEffect } from '../store'
import { getLegalEndpoints } from './api'

const endpoints = getLegalEndpoints()
export const getLegalOrderDetailsFx = createApiRetryEffect<any, any>({
  endpoint: prop('GET_LEGAL_DETAILS', endpoints),
})

export const getPaymartOrderDetailsFx = createApiRetryEffect<
  { orderID: string; type: string },
  GetTransactionDetailsRes
>({
  endpoint: prop('GET_PAYMART_DETAILS', endpoints),
})
