import { mergeDeepRight } from 'ramda'
import { ScreenSize } from '../types'
import { getBaseInputStyles } from './base-input-styles'

export const PasswordInputStyles = (screenSize: ScreenSize) =>
  mergeDeepRight(getBaseInputStyles(screenSize), {
    root: { '& input': { border: '0' } },
    innerInput: {
      border: '0',
    },
    rightSection: {
      background: 'none',
    },
  })
