import { NotificationProps } from '@mantine/notifications'
import { Fn, TFn, UserDetails } from 'ap-core'
import { createEvent } from 'effector'
import { NavigateFunction } from 'react-router'
import { SettingsSrvProps, WsChatMessage, WsSendMessage } from 'srv-declarations'
import { ApiEffectSignature } from './types'

export const translationInstanceChanged = createEvent<TFn>()
export const routerInstanceChanged = createEvent<NavigateFunction>()

export const retryFailedReqsChanged = createEvent()
export const retrySseChanged = createEvent()

export const pinModalIDChanged = createEvent<string>()

export const toggleFaqMenuChanged = createEvent()

export const tutorStepChanged = createEvent<number>()

export const resetTutorStep = createEvent()

export const skipTutorChanged = createEvent()

export const showPwaChanged = createEvent<boolean>()

export const addToApiEffects = createEvent<ApiEffectSignature>()

export const switchLangChanged: SettingsSrvProps['events']['switchLangChanged'] = createEvent()

export const sseUserDetailsChanged = createEvent<UserDetails>()

export const lastNotifyErrorChanged = createEvent<string>()

export const resetNotifyErrors = createEvent<NotificationProps>()

export const resetOperationsStore = createEvent()

export const chatSendMessageFnChanged = createEvent<Fn<WsSendMessage, any>>()

export const wsChatMessageChanged = createEvent<WsChatMessage>()

export const triggerUserGoBackground = createEvent<void>()

export const triggerUserGoBackToApp = createEvent<void>()

export const themeChanged = createEvent<'light' | 'dark'>()

export const toggleChat = createEvent()
