import { MantineTheme } from '@mantine/core'

export const getBaseModalStyles = (theme: MantineTheme) => ({
  // overlay: {
  //   background: theme.colors.dark[8]
  // },
  content: {
    boxShadow: 'none',
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    zIndex: '1000 !important',
    '& h2': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
    },
  },
})
