import { Flex } from '@mantine/core'
import { getLayoutHeight, useGetLayoutSpacings } from 'shared'
import { MobileOrDesktop } from './mobile-layout'
import { MobileTabsProps } from './types'

export const ThreeColumns = (props: MobileTabsProps) => {
  const spacing = useGetLayoutSpacings()

  return (
    <Flex
      gap={{ base: '0.75rem', md: '1rem' }}
      direction={{ base: 'column', lg: 'row' }}
      w='100%'
      h={{
        base: `calc(100dvh - ${getLayoutHeight(spacing)})`,
      }}
    >
      <MobileOrDesktop {...props} />
    </Flex>
  )
}
