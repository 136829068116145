import { Paper } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { compareWithAndBreakPoint } from 'ap-core'
import {
  T,
  always,
  cond,
  filter,
  gt,
  length,
  path,
  pipe,
  prop,
  propOr,
  values,
  whereEq,
} from 'ramda'
import { memo } from 'react'
import { StyledScrollArea } from 'shared'
import { MobileTabsProps } from '../types'

import { Condition } from '@aifory/pochti-rabotaet'
import MobileTabs from './mobile-tabs'

const getHidenCount = pipe(values, filter(Boolean), length)

const getBasisByHideCount = cond<{ count: number; isMiddle?: boolean }[], string>([
  [whereEq({ hide: 0 }), ({ isMiddle }) => (isMiddle ? '40%' : '30%')],
  [whereEq({ hide: 1 }), always('50%')],
  [whereEq({ hide: 2 }), always('100%')],
  [T, always('30%')],
])
const MobileOrDesktop = (props: MobileTabsProps) => {
  const { width } = useViewportSize()

  const hideCount = getHidenCount(propOr({}, 'hide', props) as {})
  if (!width || compareWithAndBreakPoint(gt, width, 'lg')) {
    return (
      <>
        <Condition when={!path(['hide', 'left'], props)}>
          <Paper
            pos='relative'
            style={{ flex: 1, flexBasis: getBasisByHideCount({ count: hideCount }) }}
          >
            {prop('left', props)}
          </Paper>
        </Condition>

        <Condition when={!path(['hide', 'middle'], props)}>
          <Paper
            pos='relative'
            style={{
              flex: 1,
              flexBasis: getBasisByHideCount({ count: hideCount, isMiddle: true }),
            }}
          >
            <StyledScrollArea h='100%' type='never'>
              {prop('middle', props)}
            </StyledScrollArea>
          </Paper>
        </Condition>
        <Condition when={!path(['hide', 'right'], props)}>
          <Paper
            pos='relative'
            style={{ flex: 1, flexBasis: getBasisByHideCount({ count: hideCount }) }}
          >
            {prop('right', props)}
          </Paper>
        </Condition>
      </>
    )
  }
  return (
    <StyledScrollArea
      h='100%'
      type='never'
      pos='relative'
      // viewportProps={}
    >
      <MobileTabs {...props} />
    </StyledScrollArea>
  )
}

export default memo(MobileOrDesktop)
