import ThemeProvider from 'app/ui/theme-provider'
import { AnimatePresence } from 'framer-motion'
import { Suspense, lazy } from 'react'

const LazyProviders = lazy(() => import('./lazy-providers'))

const RootProvider = () => {
  return (
    <div className='app'>
      <ThemeProvider>
        <AnimatePresence>
          <Suspense>
            <LazyProviders />
          </Suspense>
        </AnimatePresence>
      </ThemeProvider>
    </div>
  )
}

export default RootProvider
