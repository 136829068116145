import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { Account, TWallet, getLocale } from 'ap-core'
import {
  any,
  ascend,
  equals,
  findIndex,
  flatten,
  pathOr,
  pluck,
  prepend,
  prop,
  propEq,
  propOr,
  reject,
  update,
} from 'ramda'
import { safePipe } from 'shared'
import { AssetWs, MainTypes } from '../types'

export const byOrder = ascend(propOr(999, 'order'))

export const getWalletsFromGroup = (safePipe(
  prop('groups'),
  pluck('wallets'),
  flatten
)([]) as unknown) as (value: any) => TWallet[]

const getAccountsFromGroup = (safePipe(
  getWalletsFromGroup,
  pluck('accounts'),
  flatten
)([]) as unknown) as (value: any) => Account[]

export const getWalletByLocale = (v: AssetWs): TWallet | null =>
  pathOr(null, ['locales', getLocale()], v)

// export const objectExistsInGroups = (id: string, data: AssetsGroup[]) =>
//   !equals(findIndex(pathEq(id, ['wallets', 'id']), data), -1)

export const objectExistsInUngrouped = (id: string, data: TWallet[]) =>
  !equals(-1, findIndex(propEq(id, 'id'))(data))

const updateWallet = (id: string, walletsList: TWallet[], renewWallet: TWallet) => {
  const index = findIndex(propEq(id, 'id'), walletsList)
  if (equals(index, -1)) {
    return walletsList
  }
  return update(index, renewWallet, walletsList)
}

export const updateUngroupedWallets = (
  data: TWallet[],
  renewWallet: TWallet,
  type: MainTypes
) => {
  const id = prop('id', renewWallet)

  if (isNilOrEmpty(renewWallet)) return data
  if (objectExistsInUngrouped(id, data)) {
    if (equals('delete', type)) return reject(propEq(prop('id', renewWallet), 'id'), data)

    if (equals('update', type)) return updateWallet(id, data, renewWallet)

    return data
  }

  if (equals('create', type)) {
    if (any(propEq(prop('id', renewWallet), 'id'), data)) return data
    return prepend(renewWallet, data)
  }

  return data
}
