import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { Flex, Text } from '@mantine/core'
import { CardPaper, KycLimitInfoProps, getAdditionSizes, useQuery } from 'ap-core'
import { useUnit } from 'effector-react'
import {
  $KYCOperationLimit,
  getKYCLevelFx,
  getKYCOperationLimitFx,
  triggerGetKYCOperationLimit,
} from 'entities'
import { useTranslation } from 'react-i18next'
import { triggerKYCModal } from 'shared'
import { TriggerKycInfo } from '../trigger-kyc-info'
import { GetLimitIfExist, handleClick } from './utils'

export const LimitInfo = ({ type, mw }: KycLimitInfoProps) => {
  const { t } = useTranslation()
  const { 0: isLoadingLimit, 1: isLoadingLevel, 2: limit } = useUnit([
    getKYCOperationLimitFx.pending,
    getKYCLevelFx.pending,
    $KYCOperationLimit,
  ])

  useQuery({
    fn: triggerGetKYCOperationLimit,
    args: {
      operationType: type,
    },
    deps: [type],
    condition: () => !isNilOrEmpty(type),
  })

  return (
    <CardPaper
      justify='space-between'
      p={{ base: 'md', md: 'xl' }}
      w='100%'
      maw={mw}
      onClick={() => handleClick(isLoadingLimit, isLoadingLevel, triggerKYCModal)}
      cursor='pointer'
    >
      <Flex align='baseline' gap={getAdditionSizes('s8')}>
        <Text
          id='LimitInfoLevelDescriptionLimitText'
          fz={{ base: 'xs', md: 'sm' }}
          weight={600}
          c='fadedGray.7'
        >
          {t('Settings.Modals.KYC.Fields.levelDescription.limit')}
        </Text>
        <GetLimitIfExist limit={limit} isLoadingLimit={isLoadingLimit} />
      </Flex>
      <Flex id='LimitInfoIconInfo'>
        <TriggerKycInfo />
      </Flex>
    </CardPaper>
  )
}
