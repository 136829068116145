import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { resources } from 'ap-core'
import i18n from 'i18next'
import Cookies from 'js-cookie'
import { includes, keys, pipe, prop, take } from 'ramda'
import { initReactI18next } from 'react-i18next'

const supportedLanguages = keys(resources)

const defaultLanguage = 'ru'
const getNavigatorLang = pipe<[Navigator], string, string>(prop('language'), take(2))
const cookiesLang = Cookies.get('lang')
const getClientLang = (
  supportedLangs: Array<keyof typeof resources>,
  cookiesLang?: string
) => {
  if (isNilOrEmpty(cookiesLang)) {
    const browserLanguage = getNavigatorLang(navigator)

    if (includes(browserLanguage, supportedLangs)) {
      return browserLanguage
    }
    return defaultLanguage
  }
  return cookiesLang!
}

i18n.use(initReactI18next).init({
  resources,
  lng: getClientLang(supportedLanguages, cookiesLang),
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
