import {
  LoginPage,
  NotFound,
  PaymentsPage,
  RegistrationPage,
  SettingsPage,
  WalletsPage,
} from 'pages'
import { RefObject, createRef } from 'react'
import { Navigate } from 'react-router-dom'
import { getAppPath } from 'shared'

export interface RouterConfig {
  path: string
  nodeRef: RefObject<HTMLElement>
  name: string
  element: JSX.Element
  authOnly?: boolean
}

export const getRouterConfig = (): RouterConfig[] => [
  {
    path: getAppPath('signUp'),
    nodeRef: createRef<HTMLElement>(),
    name: 'registration',
    element: <RegistrationPage />,
  },
  {
    path: getAppPath('signUpWithToken'),
    nodeRef: createRef<HTMLElement>(),
    name: 'registration2',
    element: <RegistrationPage />,
  },
  {
    path: getAppPath('login'),
    nodeRef: createRef<HTMLElement>(),
    name: 'login',
    element: <LoginPage />,
  },
  {
    path: getAppPath('wallet'),
    nodeRef: createRef<HTMLElement>(),
    name: 'wallet',
    element: <WalletsPage />,
    authOnly: true,
  },
  {
    path: getAppPath('payments'),
    nodeRef: createRef<HTMLElement>(),
    name: 'payments',
    element: <PaymentsPage />,
    authOnly: true,
  },
  {
    path: getAppPath('settings'),
    nodeRef: createRef<HTMLElement>(),
    name: 'settings',
    element: <SettingsPage />,
    authOnly: true,
  },
  {
    path: '*',
    nodeRef: createRef<HTMLElement>(),
    name: '404',
    element: <NotFound />,
  },
  {
    path: '/',
    nodeRef: createRef<HTMLElement>(),
    name: 'redirect',
    element: <Navigate to='/wallet' />,
  },
]
