import { Currency, CurrencyList, RoundToByCurrency } from 'ap-core'
import { createStore, sample } from 'effector'
import { concat, fromPairs, map, mergeDeepRight, prop, props } from 'ramda'
import { safePipe } from 'shared'
import * as ef from './effects'
import { getCurrencyListFx } from './effects'
import { triggerGetCurrencyList } from './events'
import { getCurrencies } from './utils'

export const $currencyList = createStore<Currency[]>([])

export const $cryptoList = createStore<CurrencyList>({})
export const $fiatList = createStore<CurrencyList>({})

export const $roundToByCurrency = createStore<RoundToByCurrency | null>(null)

$currencyList.on(ef.getCurrencyListFx.doneData, (state, payload) =>
  concat(state, prop('currencies', payload))
)

$fiatList.on(ef.getCurrencyListFx.doneData, (state, payload) =>
  mergeDeepRight(state, prop('fiat', getCurrencies(prop('currencies', payload))))
)

$cryptoList.on(ef.getCurrencyListFx.doneData, (state, payload: any) =>
  mergeDeepRight(state, prop('crypto', getCurrencies(prop('currencies', payload))))
)

$roundToByCurrency.on(
  ef.getCurrencyListFx.doneData,
  (_, cur) =>
    safePipe(prop('currencies'), map(props(['id', 'roundTo'])), fromPairs)(null)(
      cur
    ) as RoundToByCurrency
)

sample({
  clock: triggerGetCurrencyList,
  target: getCurrencyListFx,
})
