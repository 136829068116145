import { prop } from 'ramda'
import { requestFile } from 'shared'
import { createApiRetryEffect } from '../store'
import { getApkEndpoints } from './api'
const endpoints = getApkEndpoints()
export const getAPKLinkFx = createApiRetryEffect<void, { link: string }>({
  endpoint: prop('GET_APK_LINK', endpoints),
  method: 'get',
  extraInstance: requestFile,
})
