import { openModal } from '@mantine/modals'
import { getModalIds, TFn } from 'ap-core'
import { KYCInfo } from './ui'

export const openKYCModal = (t: TFn, is430: boolean) => {
  openModal({
    title: t('Settings.Modals.KYC.title.levels'),
    modalId: getModalIds('kysLevels'),
    padding: 'lg',
    size: 'lg',
    children: <KYCInfo is430={is430} />,
  })
}
