import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { closeAllModals } from '@mantine/modals'
import { AssetInterface, TWallet, getLocale } from 'ap-core'
import { sample } from 'effector'
import Cookies from 'js-cookie'
import { __, assoc, complement, has, map, pipe, prop, propOr, sort, when } from 'ramda'
import { AuthURLs, triggerLogout } from 'shared'
import {
  assetsInterfacesChanged,
  byOrder,
  getAssetsFx,
  ungroupedAssetsChanged,
} from './assets'
import { resetOperationsStore, themeChanged } from './events'
import { $navigate } from './store'
import { $username, getUserDetailsFx, logoutFx } from './user'
import { baseIndexingAssets } from './utils'

sample({
  clock: getAssetsFx.doneData,
  source: $username,
  fn: (userName, assets) => {
    const key = `${userName}-assets-order`
    if (!localStorage.getItem(key)) {
      return localStorage.setItem(key, baseIndexingAssets(assets))
    }

    return
  },
})

sample({
  clock: getUserDetailsFx.doneData,
  fn: () => ({ locale: getLocale() }),
  target: getAssetsFx,
})

sample({
  clock: getAssetsFx.doneData,
  source: { username: $username },
  fn: ({ username }, { ungroupedWallets }) => {
    const assetsOrder = localStorage.getItem(`${username}-assets-order`)
    if (isNilOrEmpty(assetsOrder)) return ungroupedWallets
    const parsedOrder = (JSON.parse(assetsOrder!) || {}) as Record<string, number>

    const walletIDIncludesInParsed = pipe<TWallet[], string, boolean>(
      prop('id'),
      has(__, parsedOrder)
    )

    return pipe<TWallet[][], TWallet[], TWallet[]>(
      map(
        when(walletIDIncludesInParsed, (wallet) =>
          assoc(
            'order',
            propOr<number, Record<string, number>, number>(
              999,
              prop('id', wallet),
              parsedOrder
            ),
            wallet
          )
        )
      ),
      sort(byOrder)
    )(ungroupedWallets)
  },
  target: ungroupedAssetsChanged,
})

sample({
  clock: $username,
  filter: (name) =>
    !isNilOrEmpty(JSON.parse(localStorage.getItem(`${name}-asset-interfaces`) || '[]')),
  fn: (name) =>
    JSON.parse(
      localStorage.getItem(`${name}-asset-interfaces`) || '[]'
    ) as AssetInterface[],
  target: assetsInterfacesChanged,
})
sample({
  clock: themeChanged,
  fn: (theme) => {
    Cookies.set('theme', theme)
  },
})

sample({
  clock: logoutFx.finally,
  source: $navigate,
  fn: (navigate) => {
    navigate?.(AuthURLs.login.path)
    closeAllModals()
  },
  target: resetOperationsStore,
})

sample({
  clock: triggerLogout,
  source: logoutFx.pending,
  filter: complement(Boolean),
  target: logoutFx,
})

export * from '../lib'
export * from '../ui'
export * from './apk'
export * from './assets'
export * from './currency'
export * from './effects'
export * from './events'
export * from './legals'
export * from './notifys'
export * from './store'
export * from './types'
export * from './user'
export * from './utils'
export * from './ws-token'
