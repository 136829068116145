import { MantineTheme, MantineThemeOverride } from '@mantine/core'
import { always, equals, ifElse } from 'ramda'
import { breakPoints, isDark, isDarkTheme } from 'shared'
import { NativeButtonStyles } from './button-group'
import { PasswordInputStyles, TextInputStyles } from './inputs-group'
import { getBaseModalStyles } from './modal-group'
import { SelectInputStyles } from './select-group'
import { ScreenSize } from './types'

const getSizeByScreenSize = (screenSize: ScreenSize) => {
  if (equals('desktop', screenSize)) return 'lg'
  if (equals('laptop', screenSize)) return 'md'
  return 'sm'
}

export const getCustomTheme = (themeColor: 'light' | 'dark', screenSize: ScreenSize) => {
  return {
    components: {
      TextInput: {
        defaultProps: {
          size: 'lg',
          radius: '0.75rem',
        },
        styles: (theme) => TextInputStyles(screenSize),
      },
      PasswordInput: {
        defaultProps: {
          size: 'lg',
          radius: '0.75rem',
        },
        styles: (theme) => PasswordInputStyles(screenSize),
      },
      DatePickerInput: {
        defaultProps: {
          size: 'lg',
          minDate: new Date(2015, 0, 1),
          maxDate: new Date(2030, 0, 1),
        },
        styles: (theme) => SelectInputStyles(screenSize),
      },
      Select: {
        defaultProps: {
          size: 'lg',
          transitionProps: {
            transition: 'pop',
            duration: 200,
            timingFunction: 'linear',
          },
        },
        styles: (theme) => SelectInputStyles(screenSize),
      },
      Collapse: {
        defaultProps: {
          transitionDuration: 300,
          transitionTimingFunction: 'linear',
        },
      },
      LoadingOverlay: {
        defaultProps: {
          overlayColor: isDark(themeColor) ? '#141517' : '',
        },
      },
      Button: {
        defaultProps: {
          size: 'lg',
          radius: 'lg',
        },
        styles: (theme, params) => NativeButtonStyles(theme, params),
      },
      PinInput: {
        defaultProps: {
          size: getSizeByScreenSize(screenSize),
          length: 6,
          radius: '12px',
          placeholder: '',
          oneTimeCode: true,
          inputMode: 'numeric',
          type: 'number',
        },
        styles: () => ({ input: { fontWeight: 700 } }),
      },
      Switch: {
        styles: { track: { cursor: 'pointer' } },
      },
      FileInput: {
        defaultProps: {
          size: 'lg',
        },
        styles: (theme) => PasswordInputStyles(screenSize),
      },
      Modal: {
        defaultProps: {
          modalProps: { zIndex: 10000 },
          centered: true,
          overlayProps: { color: 'gray', blur: 7 },
          closeButtonProps: { size: 'md' },
          transitionProps: { transition: 'pop', duration: 300, timingFunction: 'linear' },
        },
        styles: (theme) => getBaseModalStyles(theme),
      },
      DateTimePicker: {
        defaultProps: {
          size: getSizeByScreenSize(screenSize),
          minDate: new Date(2015, 0, 1),
          maxDate: new Date(2030, 0, 1),
        },
        styles: (theme) => SelectInputStyles(screenSize),
      },
      Menu: {
        defaultProps: {
          transitionProps: {
            transition: 'fade',
            duration: 200,
            timingFunction: 'linear',
          },
        },
      },
      ScrollArea: {
        defaultProps: {
          scrollbarSize: 6,
        },
      },
      Text: {
        defaultProps: {
          fz: { base: '0.75rem', sm: '1rem' },
        },
      },
      Paper: {
        defaultProps: {
          // withBorder: true,
          shadow: 'sm',
          radius: 'lg',
        },
        styles: (theme) => ({
          root: {
            backgroundColor: isDarkTheme(theme) ? theme.colors.dark[6] : '',
            boxShadow: ifElse(
              isDarkTheme,
              always(''),
              always('0px 2px 25px 0px rgba(212, 214, 218, 0.30)')
            )(theme),
          },
        }),
      },
    },
    white: '#FFF',
    black: '#2B2B2B',
    fontFamily: 'Inter',
    headings: { fontFamily: 'Inter' },
    colorScheme: themeColor,
    colors: {
      brand: [
        '#EAE8FF',
        '#D6D5FF',
        '#B8B3FF',
        '#9388FD',
        '#7158FA',
        '#6841F3',
        '#5023DE',
        '#431DBA',
        '#381999',
      ],
      red: [
        '#FCDADA',
        '#FAC7C7',
        '#F9B4B4',
        '#F7A1A1',
        '#F58F8F',
        '#F47C7C',
        '#F26969',
        '#F15757',
        '#EF4444',
        '#DF4343',
      ],
      green: [
        '#CFF1E6',
        '#B7EAD9',
        '#9FE3CD',
        '#87DCC0',
        '#70D5B3',
        '#58CEA7',
        '#40C79A',
        '#28C08E',
        '#10B981',
        '#21A87B',
      ],
      fadedGray: [
        '#989CAD',
        '#F8FAFB',
        '#F3F5F9',
        '#EDF0F5',
        '#E5E7EB',
        '#989CAD',
        '#989CAD',
        '#989CAD',
        '#3B3D42',
        '#3B3D42',
      ],
      dark: [
        '#FFFFFF',
        '#A6A7AB',
        '#909296',
        '#5C5F66',
        '#373A40',
        '#2C2E33',
        '#25262B',
        '#1A1B1E',
        '#141517',
        '#101113',
      ],
      gray: [
        '#F8F9FA',
        '#F1F3F5',
        '#E9ECEF',
        '#DEE2E6',
        '#CED4DA',
        '#ADB5BD',
        '#868E96',
        '#495057',
        '#343A40',
        '#2B2B2B',
      ],
    },
    breakpoints: breakPoints,
    primaryColor: 'brand',
    globalStyles: (theme: MantineTheme) => ({
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },

      body: {
        ...theme.fn.fontStyles(),
      },
    }),
  } as MantineThemeOverride
}
