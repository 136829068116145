import { alwaysNew, isNilOrEmpty } from '@aifory/lil-tools-228'
import { Fn, FxFabricPayload, NotifyProps, TFn, TFnArg } from 'ap-core'
import { Effect, createEffect, createStore, sample } from 'effector'
import Cookies from 'js-cookie'
import { KyInstance } from 'ky'
import {
  T,
  __,
  always,
  call,
  compose,
  find,
  identity,
  includes,
  not,
  prepend,
  prop,
  propEq,
  reject,
  unless,
} from 'ramda'
import { NavigateFunction } from 'react-router'
import {
  FailedOn401Signature,
  addToFailed401ReqChanged,
  getEndpointName,
  getErrorMessage,
  getRetryHeaders,
  processingResponseKy,
  removeFromFailed401ReqChanged,
  request,
  safeMap,
  triggerSentryError,
} from 'shared'
import { WsSendMessage } from 'srv-declarations'
import * as ef from './effects'
import * as ev from './events'
import { themeChanged } from './events'
import { ApiEffectSignature } from './types'
export const withErrorNotification = <T, U>(eff: Effect<T, U>, skipSubs?: boolean) => {
  if (!skipSubs) {
    sample({
      clock: eff.failData,
      source: { t: $translate, lastNotifyErrors: $lastNotifyErrors },
      fn: ({ t, lastNotifyErrors }, err): NotifyProps | undefined => {
        const errorMessage = prop('message', err)
        ev.lastNotifyErrorChanged(errorMessage)

        if (includes(errorMessage, lastNotifyErrors) || isNilOrEmpty(errorMessage))
          return undefined

        return {
          t,
          type: 'fail',
          passToTranslateMessage: `Shared.Error.${getErrorMessage(
            errorMessage
          )}` as TFnArg,
          onOpen: () => {
            ev.lastNotifyErrorChanged(errorMessage)
            setTimeout(ev.resetNotifyErrors, 1500)
          },
        }
      },
      target: ef.notifyFx,
    })
    return eff
  }

  return eff
}

export const createApiRetryEffect = <Req, Res>({
  endpoint,
  method = 'post',
  skipFailSub,
  applyJson = true,
  skipParseResToJson,
  onDownloadProgress,
  extraInstance,
}: FxFabricPayload<Req, Res> & { extraInstance?: KyInstance }) => {
  const kyInst = extraInstance || request
  const effect = createEffect((json: Req) =>
    processingResponseKy<Res>(
      () =>
        kyInst[method](endpoint, {
          onDownloadProgress: (progress, chunk) =>
            onDownloadProgress?.({ progress, chunk, req: json }),
          json: applyJson ? json : undefined,
          body: !applyJson ? (json as any) : undefined,
          headers: getRetryHeaders(true),
        }),
      skipParseResToJson
    )
  )

  ev.addToApiEffects({ endpoint, effect })

  if (!skipFailSub) {
    withErrorNotification(effect)
    return effect
  }

  return effect
}
export const $failedOn401Reqs = createStore<FailedOn401Signature[]>([])

export const $isOpenedFaqMenu = createStore(false)

export const $chatOpened = createStore<boolean>(false).on(ev.toggleChat, not)

export const $tutorStep = createStore(0)

export const $skipTutor = createStore(false)

export const $showPwaGuid = createStore<boolean>(false)

export const $apiEffects = createStore<ApiEffectSignature[]>([])

export const $lastNotifyErrors = createStore<string[]>([])

export const $theme = createStore<'light' | 'dark'>(
  (Cookies.get('theme') || 'light') as 'light' | 'dark'
)
export const $chatSendMessageFn = createStore<Fn<WsSendMessage, any>>(identity).on(
  ev.chatSendMessageFnChanged,
  alwaysNew
)

$theme.on(themeChanged, alwaysNew)

$lastNotifyErrors
  .on(ev.lastNotifyErrorChanged, (_, error) => prepend(error, _))
  .reset(ev.resetNotifyErrors)
$apiEffects.on(
  ev.addToApiEffects,
  (_, eff) => unless(includes(eff), prepend(eff))(_) as ApiEffectSignature[]
)

$showPwaGuid.on(ev.showPwaChanged, alwaysNew)

$skipTutor.on(ev.skipTutorChanged, T).reset(ev.resetTutorStep)

$tutorStep
  .on(ev.skipTutorChanged, always(8))
  .on(ev.tutorStepChanged, alwaysNew)
  .reset(ev.resetTutorStep)
$isOpenedFaqMenu.on(ev.toggleFaqMenuChanged, (isOpened) => !isOpened)

$failedOn401Reqs
  .on(addToFailed401ReqChanged, (_, cur) => prepend(cur, _))
  .on(removeFromFailed401ReqChanged, (_, cur) =>
    reject(compose(includes(__, cur), prop('url')), _)
  )

export const $translate = createStore<TFn>((always('') as unknown) as TFn)
$translate.on(ev.translationInstanceChanged, alwaysNew)

export const $navigate = createStore<NavigateFunction | null>(null)
$navigate.on(ev.routerInstanceChanged, alwaysNew)

sample({
  clock: ev.retryFailedReqsChanged,
  source: { failedReqs: $failedOn401Reqs, apiEffects: $apiEffects },
  filter: ({ failedReqs }) => !isNilOrEmpty(failedReqs),
  fn: ({ failedReqs, apiEffects }) => ({
    failedReqs,
    apiEffects,
  }),
  target: ef.retryFailedOn401Fx,
})

ef.retryFailedOn401Fx.use(({ failedReqs, apiEffects }) => {
  const retryed = safeMap((req) => {
    const endpointName = getEndpointName(prop('url', req))
    const matched = find(propEq(endpointName, 'endpoint'), apiEffects)
    if (isNilOrEmpty(matched)) return

    call(prop('effect', matched!), prop('body', req))
    return prop('url', req)
  }, failedReqs)

  removeFromFailed401ReqChanged(reject(isNilOrEmpty, retryed) as string[])
})

sample({
  clock: triggerSentryError,
  target: ef.throwSentryErrorFx,
})
