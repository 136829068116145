import { prop } from 'ramda'
import { createApiRetryEffect } from '../store'
import { getCurrencyEndpoints } from './api'
import { GetCurrencyListRes } from './types'

const globalEndpoints = getCurrencyEndpoints()
export const getCurrencyListFx = createApiRetryEffect<void, GetCurrencyListRes>({
  method: 'get',
  endpoint: prop('FETCH_CURRENCY', globalEndpoints),
})
